import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { workoutApi } from "./api/workoutApi";
import { projectApi } from "./api/projectApi";
import { authApi } from "./api/authApi";
import userReducer from "./user/userSlice";
import {chatApi} from "./api/chatApi";

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    [workoutApi.reducerPath]: workoutApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [projectApi.reducerPath]: projectApi.reducer,
    [chatApi.reducerPath]: chatApi.reducer,
    userState: userReducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({}).concat([
        workoutApi.middleware,
        projectApi.middleware,
        authApi.middleware,
        chatApi.middleware
    ]),
  devTools: true,
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
