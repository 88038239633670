import React from "react";
import ProjectForm from "../components/ProjectForm";

const Test = () => {
  return (
    <>
      <ProjectForm />
    </>
  );
};

export default Test;
